body {
    margin: 0px;
    padding: 0px;
    color: #222;
}

.valuationDetails {
    max-height: 100px;
}

#valuationDetailBtn {
    margin-left: 0px !important;
}

#valuation_radio_label {
  padding-top: 13px;
}

#valuation_radio_label > span:nth-child(2) {
   font-size: 17px !important;
   font-weight: bold !important;
   /* font-family: 'Poppins-Medium' !important; */
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.circular_spiner svg{
    margin-right: 0 !important;
}

.tab-content {
    /* padding: 10px 20px; */
}

#demo-mutiple-name {
    color: black !important;
    border-radius: 3px;
    font-size: 14px !important;
}

#valuationProvider:disabled {
    background-color: #f9f9f9;
}

#validationAstrick {
   color: #ba000d;
}

#avmSave {
   max-height: 65px !important;
}

.checkedIcon {
    color: #295086;
}

.popupCancel .MuiButton-label {
    font-size: 15px !important;
}

#valuationSection {
   align-items: start !important;
}

.valuationProvider.row {
   align-items: baseline !important;
}

.list-provider {
   font-size: 14px;
   padding-left: 5px;
}

.tab-content1 {
    padding: 10px 20px;
    overflow: auto;
}

.tab-content1 section .section-level {
    padding: 20px;
    border-radius: 10px;
    background: #e9e9e9;
    border: 0.3px solid rgba(0, 0, 0, 0.5);
    overflow: auto;
}

.tab-content-user {
    padding: 65px 50px;
}

.section-level {
    background: #e9e9e9;
}

.tab-content section .section-level {
    padding: 20px;
    border-radius: 10px;
    background: #e9e9e9;
    border: 0.3px solid rgba(0, 0, 0, 0.5);
}

.section-level .section-level-inner {
    border-radius: 10px;
    background: #fff;
    border: 0.3px solid rgba(0, 0, 0, 0.5);
}

.formDiv .MuiTextField-root input {
    height: 18px !important;
}

.formDiv #phoneNumber {
    height: 18px !important;
    color: #000 !important;
    font-weight: 400 !important;
}

.MultiSelect {
    height: 30px !important;
}

.form_select {
    height: 30px !important;
}

.form_selects {
    height: 90px !important;
    margin-left: 21px !important;
}

.MuiAutocomplete-listbox {
    border: 0.2px solid #c4c1c1 !important;
    
}

.MuiAutocomplete-option {
    font-size: 14px;
}

/* Default CSS  */

.row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.d-flex {
    display: flex;
}

.justify-start {
    justify-content: flex-start !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-center {
    justify-content: center !important;
}

.align-center {
    align-items: center !important;
}

.vh-50 {
    height: 50vh !important;
}

.font-weight-bold {
    font-weight: bold;
}

.pt-50 {
    padding-top: 100px;
}

.row-direction {
    flex-direction: row !important;
}

.mr-10 {
    margin-right: 10px;
}

.formDiv label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
}

.formDiv .MuiOutlinedInput-input {
    color: #222 !important;
    padding: 5px 8px !important;
    background: #fff;
    border-radius: 3px;
    border: 0.5px solid #707070;
    font-size: 14px !important;
}

.formDiv .MuiOutlinedInput-input:hover {
    outline: transparent !important;
}

.formDiv textarea {
    color: #222 !important;
    padding: 5px 8px !important;
    background: #fff;
    border-radius: 3px;
    border: 0.5px solid #707070;
    font-size: 14px !important;
}

/* .MuiInput-underline:before {
    border-bottom: none !important;
} */
.MuiInputBase-multiline {
    padding: 6px 0 1px !important;
}

.comment-area::placeholder {
    color: #000;
}

.valuation-btn{
    width: 100%;
    min-width: unset !important; 
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    background: #067de2 !important;
    height: 35px;
}

.manualoffer-content{
    padding: 30px;
    padding-left: 15%;
}
.manualoffer-text{
    /* font-family: Poppins-Regular !important; */
    font-weight: bold !important;
    font-size: 15px !important;
}
.calculate_btn{
    border-radius: 25px !important;
    padding: 5px 19px !important;
    font-size: 12px !important;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #067de2 !important;
    border: 0px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px !important;
}
.calculate_btn_disable{
    border-radius: 25px !important;
    padding: 5px 19px !important;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.3)!important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #067de2 !important;
    border: 0px;
    cursor:default;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px !important;
}
.calculate_btn:hover{
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.calculate_btn:active{
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.val-details-text{
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.val-details-amnt{
    padding-left: 5px !important;
    display: flex;
    justify-content: start;
}
.avg_btns{
    justify-content: center;
}
.avg_confirm{
    padding: 20px !important;
}
.avg_align{
    display: flex;
    justify-content: center;
}
/* Responsive CSS */

@media (max-width: 360px) {
    .section-level {
        display: block !important;
    }
    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .tab-content section .section-level {
        padding: 10px;
    }
    .formDiv {
        padding: 5px 10px;
    }
    .formDiv .MuiTextField-root {
        width: 100%;
    }
    .formDiv #phoneNumber {
        width: 90%;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        height: 33px !important;
        width: 251px !important;
        border: 0.5px solid #707070 !important;
    }
    .form-select-mgp {
        height: 33px !important;
        width: 138px !important;
        border: 0.5px solid #707070 !important;
    }
}

@media (min-width: 361px) and (max-width: 430px) {
    .section-level {
        display: block !important;
    }
    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .tab-content section .section-level {
        padding: 10px;
    }
    .formDiv {
        padding: 5px 10px;
    }
    .formDiv label {
        margin-bottom: 5px;
    }
    .formDiv .MuiTextField-root {
        width: 100%;
    }
    .formDiv #phoneNumber {
        width: 90%;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        width: 294px !important;
        border: 0.5px solid #707070 !important;
    }
    .form-select-mgp {
        height: 33px !important;
        width: 198px !important;
        border: 0.5px solid #707070 !important;
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    
}

@media (min-width: 431px) and (max-width: 575px) {
    
    .section-level {
        display: block !important;
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
    .formDiv.row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .formDiv {
        padding: 5px 10px;
    }
    .formDiv label {
        width: 250px;
        padding-right: 10px;
    }
    .tab-content-user {
        padding: 165px 20px;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        height: 33px !important;
        width: 251px !important;
        border: 0.5px solid #707070 !important;
    }
    .tab-content-user {
        padding: 165px 20px;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        height: 33px !important;
        width: 251px !important;
        border: 0.5px solid #707070 !important;
    }
    .tab-content-user {
        padding: 165px 20px;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        height: 33px !important;
        width: 251px !important;
        border: 0.5px solid #707070 !important;
    }
    
}

@media (min-width: 576px) and (max-width: 767px) {
    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .formDiv.row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 5px;
    }
    .formDiv .MuiTextField-root {
        width: 90%;
    }
    .formDiv #phoneNumber {
        width: 90%;
    }
    .tab-content-user {
        padding: 155px 20px;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        height: 33px !important;
        width: 251px !important;
        border: 0.5px solid #707070 !important;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {
    .MuiSelect-selectMenu {
        /* multi select width */
        width: 230px !important
    }
    .app-status {
        margin-top: 80px;
        padding: 25px;
        padding-bottom: 10px;
    }
    .tab-content {
        padding: 10px 20px !important;
    }
    .tab-content-user {
        padding: 80px 20px !important;
    }
    .boxModal {
        padding: 10px 20px !important;
    }
    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .section-title {
        padding-left: 10px;
    }
    .formDiv.row {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start !important;
        padding: 8px 10px;
    }
    .valuationDetails { 
        align-items: center !important;
        justify-content: center !important;
    }
    .formDiv label {
        width: 230px;
        margin: 0px;
        margin-bottom: 5px;
        padding-right: 10px;
    }
    .formDiv .MuiTextField-root {
        width: 230px !important;
    }
    .formDiv #phoneNumber {
        width: 213px !important;
    }
    .formDiv textarea {
        width: 230px !important;
    }
    #application_status_offer {
        font-size: 14px !important;
        color: #000 !important;
        width: 230px !important;
        border-bottom: 2px solid #454545 !important;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        width: 230px !important;
        border-radius: 3px;
        height: 29px !important;
        border: 0.5px solid #707070 !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    .user-role {
        width: 100px !important;
    }
    .section-level-inner {
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: flex-end !important;
        padding: 10px 5px !important
    }
    .user-role {
        width: 100px !important;
    }
    .radio-btn-div label {
        width: 100%;
    }
    .form_layout_btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0px;
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .form_layout_btn button {
        margin: 10px 3px !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    .user-role {
        width: 90px !important;
    }
    .views-widget select {
        appearance: none;
        padding-left: 8px;
    }
    .views-widget {
        width: 220px;
        position: relative;
    }
    .views-widget::before {
        content: '';
        display: block;
        border: 5px solid transparent;
        border-top-color: #407de2;
        position: absolute;
        right: 10px;
        top: calc(50% - 2px);
    }
    .account {
        margin-left: 0px;
    }
    .row-direction {
        margin-top: -3px !important;
        margin-left: -4px !important;
    }
    .formDiv label {
        width: 150px;
        margin: 0px;
        margin-bottom: 5px;
        padding-right: 10px;
    }
    .formDiv .MuiTextField-root {
        width: 230px;
        height: 29px !important;
    }
    .formDiv #phoneNumber {
        width: 213px;
        height: 21px !important;
    }
    .MultiSelect {
        width: 100%;
    }
    .MuiInput-root {
        width: 235px !important
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    .user-role {
        width: 100px !important;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.form_select {
        width: 230px !important;
    }
    .section-level-inner {
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: flex-end !important;
        padding: 10px 5px !important;
    }
    .user-role {
        width: 100px !important;
    }
    .formDiv.radio-btn-div .radio-label {
        width: 150px !important;
    }
    .form_layout_btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0px;
    }
    .MuiInput-root {
        width: 185px !important
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .form_layout_btn button {
        margin: 10px !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    .user-role {
        width: 90px !important;
    }
    .views-widget select {
        appearance: none;
        padding-left: 8px;
    }
    .views-widget {
        width: 208px;
        position: relative;
    }
    .views-widget::before {
        content: '';
        display: block;
        border: 5px solid transparent;
        border-top-color: #407de2;
        position: absolute;
        right: 0px;
        top: calc(50% - 2px);
    }
    .date-input p {
        display: none;
    }
    .date-input .MuiInputBase-formControl {
        width: 230px !important;
    }
    .date-input .MuiInput-underline:before {
        content: '';
    }
    .Mui-disabled input {
        padding-bottom: 4px;
    }
    .Mui-disabled input[placeholder] {
        color: #000 !important;
        opacity: 1;
    }
    .Mui-disabled .MuiInputAdornment-root {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .tab-content {
        padding: 20px !important;
    }
    .app-status {
        margin-top: 80px;
        padding: 25px;
        padding-bottom: 10px;
    }
    .boxModal {
        padding: 10px 20px !important;
    }
    .tab-content-user {
        padding: 80px 20px !important;
    }
    .section-title {
        padding-left: 10px;
    }
    .formDiv.row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 8px 10px;
    }
    .valuationDetails { 
        align-items: center !important;
    }
    .formDiv label {
        width: 150px;
        margin: 0px;
        margin-bottom: 5px;
        padding-right: 10px;
    }
    .formDiv .MuiTextField-root {
        width: 230px;
    }
    .formDiv #phoneNumber {
        width: 213px;
    }
    .formDiv textarea {
        width: 230px !important;
    }
    #application_status_offer {
        font-size: 14px !important;
        color: #000 !important;
        width: 230px !important;
        border-bottom: 2px solid #454545 !important;
    }
    .MultiSelect {
        width: 100%;
    }
    .form_select {
        width: 230px !important;
        border-radius: 3px;
        border: 0.5px solid #707070 !important;
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    .user-role {
        width: 100px !important;
    }
    .section-level-inner {
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: flex-end !important;
        padding: 10px 0px !important;
    }
    .user-role {
        width: 100px !important;
    }
    .formDiv.radio-btn-div .radio-label {
        width: 150px !important;
    }
    .form_layout_btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0px;
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .form_layout_btn button {
        margin: 10px !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
    }
    .user-role {
        width: 150px !important;
    }
    .views-widget select {
        appearance: none;
        padding-left: 8px;
    }
    .views-widget {
        width: 220px;
        position: relative;
    }
    .views-widget::before {
        content: '';
        display: block;
        border: 5px solid transparent;
        border-top-color: #407de2;
        /*change in this line color*/
        position: absolute;
        right: 10px;
        top: calc(50% - 2px);
    }
    .account {
        margin-left: 0px;
    }
    .row-direction {
        margin-top: -3px !important;
        margin-left: -4px !important;
    }
    .date-input p {
        display: none;
    }
    .date-input .MuiInputBase-formControl {
        width: 230px !important;
    }
    .date-input .MuiInput-underline:before {
        content: '';
    }
    .Mui-disabled input {
        padding-bottom: 4px;
    }
    .Mui-disabled input[placeholder] {
        color: #000 !important;
        opacity: 1;
    }
    .Mui-disabled .MuiInputAdornment-root {
        display: none;
    }
}

@media (min-width: 1200px) {
    .boxModal {
        padding: 20px !important;
    }
    .tab-content {
        /* padding: 20px !important; */
    }
    .tab-content-user {
        padding: 80px 50px !important;
    }
    .offers-tab {
        padding: 0px 50px !important;
    }
    .user-section {
        padding-left: 150px;
        padding-right: 150px;
    }
    .MultiSelect {
        width: 38%;
    }
    .tab-content section .section-level {
        padding: 30px 40px;
    }
    .section-title {
        font-size: 18px !important;
        padding: 10px 0px;
    }
    .form_select {
        width: 250px !important;
        border: 0.5px solid #707070 !important;
    }
    .send_offer_select{
        width: 265px !important;
        border: 0.5px solid #707070 !important;
        height: 30px !important;
    }
    .formDiv label {
        width: 250px;
    }
    .MuiInputBase-root {
        border: 0 !important;
    }
    .MuiSelect-select {
        height: 1px !important;
        padding: 4px !important;
        padding-bottom: 3px !important;
    }
    .formDiv.row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 10px;
        padding-right: 30px;
    }
    .formDiv .MuiTextField-root {
        width: 250px;
    }
    .formDiv #phoneNumber {
        width: 232px;
    }
    .formDiv textarea {
        width: 320px !important;
    }
    #application_status_offer {
        color: #000 !important;
        font-size: 14px !important;
        width: 237px;
        border-bottom: 2px solid #454545 !important;
     }
    .section-level.extra-top {
        padding-top: 50px !important;
        position: relative
    }
    .section-level.extra-top::before {
        content: 'Borrower';
        display: block;
        position: absolute;
        padding-left: 10px;
        top: 20px;
        font-weight: bold;
        font-size: 14px;
    }
    .section-level .section-level-inner {
        padding: 30px 0px;
    }
    .formDiv.radio-btn-div .radio-label {
        width: 52% !important;
    }
    .formDiv.radio-btn-div div {
        width: 48% !important;
        /* display: inline; */
    }
    .formDiv select {
        color: #222 !important;
        padding: 5px 8px !important;
        background: #fff;
        border-radius: 3px;
        border: 0.5px solid #707070;
        width: 250px;
        margin-left: -3px;
    }
    .views-widget select {
        appearance: none;
    }
    .views-widget {
        width: 247px;
        position: relative;
    }
    .views-widget::before {
        content: '';
        display: block;
        border: 5px solid transparent;
        border-top-color: #407de2;
        position: absolute;
        right: 10px;
        top: calc(50% - 2px);
        pointer-events: none;
    }
    .app-status {
        padding: 25px;
        padding-top: 100px;
        padding-bottom: 0px;
    }
    .row-direction {
        margin-left: 0px !important;
        background-color: #e9e9e9;
    }
    .views-widget select {
        appearance: none;
    }
    .views-widget {
        width: 247px;
        position: relative;
    }
    .views-widget::before {
        content: '';
        display: block;
        border: 5px solid transparent;
        border-top-color: #407de2;
        position: absolute;
        right: 10px;
        top: calc(50% - 2px);
    }
    .views-widget-offer select {
        appearance: none;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .views-widget-offer::before {
        content: '';
        display: block;
        border: 5px solid transparent;
        border-top-color: #407de2;
        position: absolute;
        right: 0px;
        top: calc(50% - 2px);
    }
    .views-widget-offer{
        width: 260px;
        position: relative;
    }
    .user_select .views-widget {
        width: 100px;
    }
}

/* Error Msg CSS */

.error-msg {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    color: #ba000d;
    font-weight: normal;
}

.error-msg-form {
    display: flex;
    justify-content: flex-start;
    margin-left: 48% !important;
    align-items: center;
    color: #ba000d;
    font-weight: normal;
}

.formDiv label .red-color {
    color: #ba000d;
}

button.Mui-disabled {
    color: rgba(0, 0, 0, 0.3)!important;
    cursor: not-allowed !important;
}

svg:not(:root) {
    overflow: hidden;
    color: #067de2 !important;
}

#deleteIconBtn {
    overflow: hidden;
    color: #D3D3D3 !important;
    margin-right: 5px;
    font-size: 16px;
}

.MuiSelect-select:focus {
    background-color: white !important;
    border: 0.5px solid #707070 !important;
}

.MuiSelect-select {
    background-color: white !important;
    border: 0.5px solid #707070 !important;
}

.more_btn_min {
    min-width: 55% !important;
}

.w-70 {
    width: 70px !important;
}

.MuiTabs-scrollable {
    overflow-y: hidden;
}

.MuiPopover-paper {
    border: 0.5px solid !important
}


/* MAC CSS  */

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {
        #application_status_offer {
            color: #000 !important;
            font-size: 14px !important;
            width: 246px;
            border-bottom: 2px solid #454545 !important;
         }
         #statusSelect .MuiPopover-paper {
            top: 70px !important;
          }
          #application_status_offer .MuiPopover-paper {
            top: 215px !important;
          }
    }
}

/****Review Matrix ***/

#TblHeadTitle {
    padding: 0px;
    font-weight: bold;
    font-size: 14px;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    width: 310px;
    padding-left: 6px !important;
    background-color: #e9e9e9;
}

#TableHeadCell {
    border: 1px solid rgb(224, 219, 219);
    font-weight: bold !important;
    font-size: 14px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    padding: 5px !important;
    width: 80px;
    background-color: #e9e9e9
}

.MuiInputBase-input.Mui-disabled {
    opacity: 1;
    background: #f4f0ec;
}

.more_btn_min {
    min-width: 55% !important;
}

.w-70 {
    width: 70px !important;
}

.MuiTabs-scrollable {
    overflow-y: hidden;
}

/* MAC CSS  */

@media not all and (min-resolution:.001dpcm) {
    .row-direction {
        margin-left: -10px !important;
    }
    @supports (-webkit-appearance: none) {
        .row-direction {
            margin-left: -14px !important;
        }
        .formDiv .MuiTextField-root input {
            height: 18px !important;
        }
        .formDiv #phoneNumber {
            height: 18px !important;
        }
        .MultiSelect {
            height: 30px !important;
        }
        .form_select {
            height: 30px !important;
        }
        button.MuiButtonBase-root.MuiButton-root.primary_btn {
            display: flex;
            justify-content: 'center';
            align-items: center;
        }
        @media (min-width: 1200px) {
            .formDiv.row {
                justify-content: flex-start;
            }
            .formDiv label {
                width: 230px;
            }
            #estimatedClosingCost {
                width: 210px !important;
            }
            .row-direction {
                margin-left: -10px !important;
            }
        }
    }
}

/****Review Matrix ***/

#TblHeadTitle {
    padding: 0px;
    font-weight: bold;
    font-size: 14px;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    width: 310px;
    padding-left: 6px !important;
    background-color: #e9e9e9;
}

#TableHeadCell {
    border: 1px solid rgb(224, 219, 219);
    font-weight: bold !important;
    font-size: 14px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    padding: 5px !important;
    width: 80px;
    background-color: #e9e9e9
}

.MuiInputBase-input.Mui-disabled {
    opacity: 1;
    background: #f9f9f9;
}

.circularProgress {
    margin: 250px !important
}

.primary-text {
    color: #407de2;
}

.secondary-text {
    color: #222;
    /* opacity: 0.5; */
}

/* Date Input CSS */

.date-input {
    color: #222 !important;
    height: 28px !important;
    background: #fff;
    border-radius: 3px !important;
    border: 0.5px solid #707070 !important;
    font-size: 14px !important;
}

.date-input input {
    color: #222 !important;
    padding: 6px;
    font-size: 14px !important;
}

.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar button:nth-child(1) {
    width: 120px !important;
    top: 40px;
    left: -60px;
}

.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar button:nth-child(2) {
    padding-bottom: 30px;
}

.MuiPickersToolbarText-toolbarTxt {
    color: #fff;
}

.MuiDialogActions-root {
    padding: 0px;
}

.date-input input[placeholder] {
    color: #222 !important;
}

/* MuiButtonBase-root MuiButton-root MuiButton-text MuiPickersToolbarButton-toolbarBtn */

.date-input p {
    display: none;
}

.date-input .MuiInputBase-formControl {
    width: auto !important;
}

.date-input .MuiInput-underline:before {
    display: none;
}

.Mui-disabled input {
    padding-bottom: 4px;
    font-size: 14px !important;
}

.Mui-disabled input[placeholder] {
    color: #000 !important;
    opacity: 1;
}

.Mui-disabled .MuiInputAdornment-root {
    display: none;
}

/* Page Loader CSS */

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #3498db;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) and (max-width:1200px) {
    .user_select select {
        width: 80px !important;
    }
    .user_select .views-widget {
        width: 70px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
    .user_select select {
        width: 80px !important;
    }
    .user_select .views-widget {
        width: 70px !important;
    }
    @media not all and (min-resolution:.001dpcm) {
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .row-direction {
                margin-left: -14px !important;
            }
        }
        @supports (-webkit-appearance: none) {
            .row-direction {
                margin-left: -4px !important;
            }
            .MuiSelect-selectMenu {
                /* multi select width */
                width: 230px !important
            }
            .form_layout_btn button span.MuiButton-label {
                margin-top: -4px !important;
            }
            .primary_btn span.MuiButton-label {
                margin-top: -4px !important;
            }
            /* custom_pagination */
            .custom_pagination .MuiTablePagination-toolbar .MuiTablePagination-caption {
                transform: translate(55px, 16px) !important;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) and (orientation: landscape) {
    .user_select select {
        width: 80px !important;
    }
    .user_select .views-widget {
        width: 70px !important;
    }
    @media not all and (min-resolution: .001dpcm) {
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            .row-direction {
                margin-left: -14px !important;
            }
        }
        @supports (-webkit-appearance: none) {
            .row-direction {
                margin-left: -4px !important;
            }
            .MuiSelect-selectMenu {
                /* multi select width */
                width: 230px !important
            }
            .form_layout_btn button span.MuiButton-label {
                margin-top: -4px !important;
            }
            .primary_btn span.MuiButton-label {
                margin-top: -4px !important;
            }
            /* custom_pagination */
            .custom_pagination .MuiTablePagination-toolbar .MuiTablePagination-caption {
                transform: translate(55px, 16px) !important;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @media (min-width: 1200px) {
        @media not all and (min-resolution: .001dpcm) {
            .row-direction {
                transform: translate(-7px, 0px) !important;
                margin-left: -30px;
            }
        }
    }
}

@media not all and (min-resolution: .001dpcm) {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        @media (min-width: 1200px) {
            .row-direction {
                transform: translate(-7px, 0px) !important;
            }
        }
    }
    @supports (-webkit-appearance: none) {
        @media (min-width: 1200px) {
            .row-direction {
                transform: translate(-7px, 0px) !important;
            }
            .date-input .MuiInputBase-formControl {
                width: 250px;
            }
        }
    }
}

@media (min-width:320px) and (max-width: 700px) { 
    .loanformDesktop {
        display: none;
    }
    .loanformMobile {
        display: block;
    }
    .realestateformDesktop {
        display: none;
    }
    .realestateformMobile {
        display: block;
    }
    .valuationDetails {
        max-height: 200px !important;
    }
}

@media (min-width:700px) {
    .loanformDesktop {
        display: block;
    }
    .loanformMobile {
        display: none;
    }
    .realestateformDesktop {
        display: block;
    }
    .realestateformMobile {
        display: none;
    }
}

/* Textarea css  */

.comment-area {
    min-height: 80px;
    resize: none;
    overflow-y: scroll !important;
}

.MuiAlert-root svg {
    color: #fff !important;
}

/* Radio Btn shadow CSS */

.MuiIconButton-root:hover {
    background-color: transparent !important;
}

/* +/- More info Btn shadow CSS */

/* .formDiv .MuiButton-root:hover {
    background-color: transparent !important;
} */

.MuiSelect-select.MuiSelect-select {
    height: 25px !important;
    padding-bottom: 0px !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @media (min-width: 1200px) {
        .row-direction {
            margin-left: 0px !important;
        }
    }
}

form input::-webkit-validation-bubble-message, form select::-webkit-validation-bubble-message, form textarea::-webkit-validation-bubble-message {
    display: none !important;
}

.app_select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5px !important;
}

.app_select label {
    font-weight: 500;
    margin-right: 10px;
}

.app_select select {
    height: 35px;
    padding: 8px 4px;
    padding-left: 10px;
    border-radius: 5px;
    width: 180px;
}
.app_select select:focus{
    outline: none;
}

.MuiSelect-select.MuiSelect-select#offer_template {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    /* font-family: "Poppins Medium", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .app_select{
        padding-left: 10px;
    }
    .app_select select {
        width: 100%;
    }
    .app_select .views-widget::after{
        content: '';
        display: block;
        width: 25px;
        height: 80%;
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        margin: 2px;
        pointer-events: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .app_select .views-widget::before{
        z-index: 10;
        right: 10px;
        pointer-events: none;
    }
    .error-msg-form {
        display: flex;
        justify-content: flex-start;
        margin-left: 3% !important;
        align-items: center;
        color: #ba000d;
        font-weight: normal;
    }
    #valuationBtnSource > span {
        font-size: 11px !important;
    }
    #valuationBtnDetails > span {
        font-size: 11px !important;
    }
}

.formDiv .numberField {
    color: #222;
    font-size: 14px;
    border-radius: 3px;
    padding: 5px 8px;
    border: 0.5px solid #707070;
    /* outline: transparent !important; */
}

input#phoneNumber:focus {
    border: 2px solid;
}

@media (min-width: 1200px) {
    .formDiv .numberField {
        height: 18px;
        width: 232px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .formDiv .numberField {
        height: 18px;
        width: 212px;
    }
}

@media (min-width:300px) and (max-width:600px) {
    #application_status_offer {
        color: #000 !important;
        font-size: 14px !important;
        width: 237px;
        border-bottom: 2px solid #454545 !important;
     }
}
@media only screen and (min-width: 992px) {
    #valSaveBtnOne {
        display: block !important;
    }

    #valSaveBtnTwo {
        display: none !important;
    }
}

@media only screen and (max-width: 991px) {
    #valSaveBtnOne {
        display: none !important;
    }

    #valSaveBtnTwo {
        display: block !important;
        margin-top: -50px !important;
    }
}

#TblBodyTitle{
    width: 400px;
}
.insights-cell{
    padding: 5px !important;
}
.MuiAutocomplete-paper{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}