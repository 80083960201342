.bgimage {
    -webkit-background-size: 100%;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow:none;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    flex-grow: 1;
    width:100vw;
    height:100vh;
    position:fixed;
      top:0px;
      bottom:0px;
      left:0px;
      right:0px; 
    height:auto;width:100%
  }
.MuiPaper-elevation4 {
  box-shadow:none !important;
}
.MuiAppBar-colorPrimary {
    color: black !important;
    background-color: transparent !important;
  }
  .makeStyles-form-2 {
    width: 100%;
  } 
  
  .login_img{
    padding-left:15px;
    margin-top:12px
  }
 
