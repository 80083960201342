.colors .MuiInputBase-input[placeholder] {
  color: black !important;
}

.family {
  /* font-family: 'Poppins-Regular'; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  border: none !important;
}

.user_select{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto !important;
}

.user_select label{
  font-weight: 500;
  margin-right: 10px;
}

.user_select select{
  height: 35px;
  padding: 8px 4px;
  padding-left: 10px;
  border-radius: 5px;
  width: 100px;
}
.primary_btn_disabled {
  margin: 20px 0px !important;
  border-radius: 25px !important;
  padding: 10px 15px !important;
  font-size: 12px !important;
  height: 35px;
  background-color: #EBEBE4 !important;
  min-width:155px !important
}

.deleteText {
  font-size: 14px
}

.app_link {
  color: #067de2 !important;
  cursor: pointer;
}

.app_link:hover {
  text-decoration: underline;
}

.MuiPopover-paper {
  border: 0.5px solid !important
}

/* MAC CSS  */

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance: none) {
    #statusSelect .MuiPopover-paper {
      top: 70px !important;
    }
    #application_status_offer .MuiPopover-paper {
      top: 215px !important;
    }
  }
}