.head {
  background-color: #e9e9e9;
}

.rowcls {
  background: #e9e9e9 !important;
  padding: 0px;
}
.table_border_body > .table_border{
  border: 4px solid #067de2;
}
.table_border{
  border: 4px solid #067de2;
}
.boder_bottom_0{
  border-bottom: 1px solid transparent !important;
}
.boder_top_0{
  border-top: 1px solid transparent !important;
}
.table_layout_off{
  /* overflow-x: initial !important; */
}
.table_border_body .MuiTableRow-root{
  border-collapse: unset !important;
}
.History{
  margin-top:2rem !important;
}


.primaryColor, .primaryColor svg {
  color: rgb(6, 125, 226) !important;
}