/* tabs */

.makeStyles-padding-2 {
    padding: 0px !important;
}

#application_status {
   color: #000 !important;
   font-size: 14px !important;
   border-bottom: 2px solid #454545 !important;
}

.Formpaddings {
    margin-top: 66px;
    padding-top: 0px !important;
    margin-top: 0em !important;
}

.Component-root-13.Component-selected-14 {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: #1890ff !important;
    color: white !important;
}

.Component-root-13 {
    max-width: 82px;
    height: 29px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    margin-right: 5px !important;
    text-transform: none;
    background-color: #e9e9e9 !important;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
}

.Component-root-7.Component-selected-8 {
    color: white !important;
    background-color: #1890ff !important;
}

.makeStyles-root-1 {
    flex-grow: 1;
}

.MuiBox-root-24 {
    border: 2px solid #1890ff !important
}

.Component-root-27.Component-selected-28 {
    color: #1890ff;
    font-weight: 500;
    background-color: #1890ff !important;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    color: white !important
}

.paddingform {
    margin-top: 66px;
    padding-top: 44px !important
}

.MuiGrid-item {
    box-sizing: 'none' !important;
}

.Component-root-13 {
    font-weight: 550 !important;
    color: black !important
}

.MuiTab-textColorInherit {
    opacity: 1 !important;
    color: black !important;
    background-color: #e9e9e9 !important;
}

.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    color: white !important;
    background-color: #1890ff !important;
}

.MuiTabs-root {
    margin-top: 10px !important;
    min-height: 30px !important;
    height: 40px !important;
}

.MuiTab-root {
    min-width: 72px !important;
    margin-right: 2px !important;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
}

.MuiTabs-flexContainer {
    margin-left: 22px !important;
    margin-right: 22px!important;
}

.PrivateTabIndicator-colorSecondary-24 {
    background-color: #1890ff!important;
}

span.MuiTab-wrapper {
    /* font-family: 'Poppins-Regular'; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 610 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
}

.tab3 .boxModal {
    padding: 0px !important;
}

#offerHeader {
    margin: 10px !important;
}