.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}
/* .account{
    margin-left: 50px;
} */
svg:not(:root) {
    overflow: hidden;
    margin-right:5px;
    font-size:large;
}
.navbar_item .MuiButton-label{
    font-size: 16px !important;
    font-weight: 600 !important;
}
.navbar_item:hover .MuiButton-label{
    color: #067de2;
}
.MuiAvatar-colorDefault {
    color: black !important;
    /* font-family:Poppins-Regular !important;        */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-color: white !important;
    font-size: small !important;
    border: 1px solid #067de2 !important;
}

.MuiButton-root {
    max-width: 295px !important;
    margin: none !important;
}

    .usericon{
        align-items: center;
        margin-left: 56px;
        margin-right: 56px;
        color: white !important;
        background-color: #067de2!important; 
        /* font-family:Poppins-Regular !important;        */
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
       
    }

    .blackColor span.MuiButton-label{
        color:#067de2 ;
        font-size: 16px !important;
        font-weight:bold !important;
    }

    .MuiMenuItem-root {
        /* font-family: 'Poppins-Regular' !important; */
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 14px !important
    }