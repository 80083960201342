h1 {
  font-size: 1.6rem !important;
}

span {
  font-size: 14px !important;
}

h2 {
  font-size: 2rem !important;
}

.margin_top {
  margin-top: 11px !important;
}

.forgot_title .MuiTypography-body1 {
  margin: 4px 0 20.5px 138.5px !important;
  /* font-family: Poppins-ExtraLight!important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 6px !important;
  font-weight: 200 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: end !important;
  color: #dbcece !important;
}

.icons {
  color: black !important;
  font-size: 3rem !important;
  display: inline-flex
}

.top {
  text-align: left;
  color: black !important;
  padding-left: 10px;
  padding-top: 10px
}

.logo {
  font-size: larger;
  display: inline-flex;
}

.MuiInput-underline::after {
  border-bottom: 1.1px solid #d6d7d8 !important
}

.MuiInput-underline:before {
  border-bottom: 1.1px solid #d6d7d8 !important
}

.makeStyles-submit-3 {
  padding: 3px 66px !important;
  /* margin: 20.5px 42.5px 0 49.5px !important; */
  /* margin: 24px 50px 16px !important; */
}

.MuiButton-containedPrimary {
  background-color: #067de2 !important;
}

.MuiButton-root {
  max-width: 295px !important;
  /* margin: 20.5px 42.5px 15px 49.5px !important; */
  margin: 20px 10px !important;
}

.h-200vh { 
  margin-bottom: 12px;
}

svg:not(:root) {
  overflow: hidden;
  color: white !important;
}


.MuiButton-root {
  text-transform: capitalize !important;
}
.size{
  padding: 3px 66px !important;
  margin: 20.5px 42.5px 0 49.5px !important;  
}
.MuiFormControl-marginNormal {
  margin-top: 46px !important;
}

.MuiInputBase-input {
  font: inherit !important;
  /* font-family: Poppins-Light !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300 !important;
  font-stretch: normal !important;
  line-height: 1.53 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: white !important;
}

.MuiInputBase-input[placeholder] {
  color: white !important;
}

span.MuiButton-label {
  /* font-family: Poppins-Regular !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 22px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
}

.MuiInputAdornment-positionStart {
  margin-right: 14px !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbcece !important;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #dbcece !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #dbcece !important;
  opacity: 1 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #dbcece !important;
  opacity: 1 !important;
}

.WithStyles\(ForwardRef\(DialogContent\)\)-root-6{
  width:511px !important
}
.forgotBold{
  font-Size: 14px;
   align-items:center;
   text-align:center;
   /* font-Family:'Poppins-Regular'!important; */
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   
}
.modal-body{
  width: 511px !important;

}

  
.color .MuiInputBase-input[placeholder] {
  
  color:black !important;
  width:350px;

}
.MuiFormControl-marginNormal {
  margin-bottom: 0px !important;
}

.show:not(:root) {
  color: white !important;
  font-size: 21px;
}

.bgimage .MuiInputBase-root.MuiInput-root.MuiInput-underline{
  width: 100% !important;
}