body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: Poppins-ExtraLight;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-ExtraLight.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-ExtraLight.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-ExtraLight.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-ExtraLight.ttf') format('ttf'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Poppins-Light;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-Light.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-Light.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-Light.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-Light.ttf') format('ttf'), /* Safari, Android, iOS */
}


@font-face {
  font-family: Poppins-Bold;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-Bold.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-Bold.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-Bold.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-Bold.ttf') format('ttf'), /* Safari, Android, iOS */
}





@font-face {
  font-family: Poppins-BoldItalic;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-BoldItalic.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-BoldItalic.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-BoldItalic.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-BoldItalic.ttf') format('ttf'), /* Safari, Android, iOS */
}

@font-face {
  font-family: Poppins-Black;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-Black.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-Black.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-Black.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-Black.ttf') format('ttf'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Poppins-BlackItalic;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-BlackItalic.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-BlackItalic.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-BlackItalic.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-BlackItalic.ttf') format('ttf'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-Regular.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-Regular.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-Regular.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-Regular.ttf') format('ttf'), /* Safari, Android, iOS */
}


@font-face {
  font-family: Poppins-Medium;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-Medium.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-Medium.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-Medium.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-Medium.ttf') format('ttf'), /* Safari, Android, iOS */
}




@font-face {
  font-family: Poppins-SemiBold;
  font-style: normal;
  font-weight: normal;
  src:  url('../src/Font/Poppins/Poppins-SemiBold.ttf');
  /* IE9 Compat Modes */
  src: local('Poppins'), local('Poppins'), url('../src/Font/Poppins/Poppins-SemiBold.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('../src/Font/Poppins/Poppins-SemiBold.ttf') format('ttf'), /* Super Modern Browsers */
  url('../src/Font/Poppins/Poppins-SemiBold.ttf') format('ttf'), /* Safari, Android, iOS */
}

