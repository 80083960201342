body {
    padding: 0px;
    margin: 0;
    color: #000;
}

#valuation_info_msg {
    margin-top: 5px;
}

.partner-contact-err-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ba000d;
    font-weight: normal;
}

.circular_spiner svg {
    margin-right: 0 !important;
}

#property_address {
    max-width: 20px !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

#app_email_listing {
    max-width: 20px !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

#statusSelect {
    font-size: 14px !important;
    display: flex;
    align-items: center !important;
    border-bottom: 2px solid #454545 !important;
}

#originatorSelect {
    font-size: 14px !important;
    display: flex;
    align-items: center !important;
    border-bottom: 2px solid #454545 !important;
}

.mark {
    color: red;
}

.size {
    padding: 3px 66px !important;
    margin: 20.5px 42.5px 0 49.5px !important;
}

.secondary_btn {
    margin: 30px 0px !important;
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    height: 35px;

}

.primary_btn {
    margin: 30px 0px !important;
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    background: #067de2 !important;
    height: 35px;
}

.final_ppg_btn {
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    background: #067de2 !important;
    height: 35px;
    color: #fff !important;
    border: 1px solid #067de2;
    cursor:pointer  !important;
}

.emailOfferSubPoints {
    padding-top: 10px;
}

.hrLine {
    margin-top: 20px;
}

.primary_btn_border {
    margin: 30px 0px !important;
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    height: 35px;
}

.primary_btn span.MuiButton-label {
    font-size: 14px !important;
}

.primary_btn span.MuiButton-label svg {
    color: #fff !important;
}

/* Table CSS Modofications */

.table tbody tr td:nth-child(1) {
    text-align: left;
    color: #067de2;
    cursor: pointer !important;
}

.MuiTableCell-alignRight {
    text-align: left !important;
}

.table {
    border: 0.5px solid #707070;
    margin: 10px 0px;
    border-radius: 3px !important;
}

.table thead {
    background: #e9e9e9;
}

.table tbody tr:nth-child(even) {
    background: #e9e9e9;
}

.table svg {
    color: #067de2 !important;
}

.table tbody tr th {
    color: #067de2;

}
.table tfoot tr th {
    color: #186f1b;

}

.table tbody tr td:nth-child(1) {
    color: #067de2;
}

.table tbody tr th {
    font-weight: 600 !important;
    padding-left: 15px !important;
    line-height: 1.1;

}
.table tfoot tr th {
    font-weight: 600 !important;
    padding-left: 15px !important;
    line-height: 1.1;
    font-size: 0.875rem;
}

.table thead tr th {
    /* font-family: 'Poppins-Regular' !important; */
    font-size: 13px;
    line-height: 1.2rem;
    flex-direction: row !important;
}

.table thead tr th:nth-child(1) {
    font-weight: 600 !important;
    padding-left: 10px !important;
}

.table thead tr th:nth-child(2) {
    max-width: 120px;
}

.table .MuiTableCell-sizeSmall {
    padding: 6px 6px;
    /* font-family: 'Poppins-Regular'; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.Formpadding {
    margin-top: 8em !important;
}

.Formpaddingtab {
    margin-top: 1em !important;
}

.form_layout {
    padding: 20px;
    border-radius: 10px;
    background: #e9e9e9;
    border: 0.3px solid rgba(0, 0, 0, 0.5);
}

.form_layout_div {
    display: flex;
    align-items: center;
    margin: 2px 0px;
}

.form_layout_div label {
    padding-right: 40px;
    font-weight: 600;
    min-width: 220px;
    max-width: 220px;
    font-size: 14px;
}

.form_layout_div input {
    border: 0.5px solid #707070;
    border-radius: 3px;
    background-color: #fff !important;
    min-height: 24.9px;
    min-width: 250px;
    max-width: 250px;
    font-size: 12px;
}

.form_layout_div1 {
    display: flex;
    align-items: center;
    margin: 2px 0px;
}

.form_layout_div1 label {
    padding-right: 40px;
    font-weight: 600;
    min-width: 217px;
    max-width: 217px;
    font-size: 14px;
    padding-left: 10px;
}

.form_layout_div1 input {
    border: 0.5px solid #707070;
    border-radius: 3px;
    background-color: #fff;
    min-height: 24.9px;
    min-width: 251px;
    max-width: 257px;
    font-size: 12px;
}

.form_layout_div2 {
    display: flex;
    align-items: center;
    margin: 2px 0px;
}

.form_layout_div2 label {
    padding-right: 37px;
    font-weight: 600;
    min-width: 220px;
    max-width: 220px;
    font-size: 14px;
    padding-left: 4px;
}

.form_layout_div2 input {
    border: 0.5px solid #707070;
    border-radius: 3px;
    background-color: #fff;
    min-height: 24.9px;
    min-width: 250px;
    max-width: 250px;
    font-size: 12px;
}

.form_layout_div .form_select {
    border: 1px solid #707070;
    border-radius: 3px;
    background-color: #fff;
    min-height: 29px;
    min-width: 256px;
    max-width: 256px;
    font-size: 12px;
    font-weight: 600 !important;
}

.form_layout_div .form_select .MuiSelect-select.MuiSelect-select {
    color: #000 !important;
    background: #fff;
    padding-left: 10px;
    height: 0px;
    min-width: 220px;
}

.form_layout_div .form_select .MuiSvgIcon-root.MuiSelect-icon {
    margin-top: 2px;
    color: #067de2 !important;
}

.form_valoff_btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form_layout_btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form_layout_btn button span.MuiButton-label {
    font-size: 14px !important;
}

.form_valoff_btn button {
    margin: 0px 10px 20px 10px !important;
    padding: 7px 30px !important;
    border-radius: 25px !important;
    padding: 8px 30px !important;
    font-size: 12px !important;
}

.validOfferText {
    text-align: center;
    font-size: 16px;
}

.validOfferHeading {
    text-align: center;
    font-size: 20px !important;
}

.extend_valid_offer_container {
    padding: 0px 50px !important;
}

.validOfferForm {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.validOfferForm .new_expiry_date {
    position: relative;
    left: 50px;
    width: 150px !important;
}

.form_layout_more {
    background: white;
    border-radius: 15px;
    border: 1px solid grey;
    padding: 18px 0px 18px;
}

.MuiButton-outlinedPrimary {
    border-color: #067de2 !important;
}

.table thead tr th {
    font-weight: 600;
}

/* custom_pagination */
.custom_pagination .MuiTablePagination-toolbar {
    position: relative;
    margin: 10px 0px;
}

.custom_pagination .MuiTablePagination-toolbar .MuiTablePagination-caption {
    position: absolute;
    margin: 0px auto;
    transform: translateX(60px);
    width: 75px;
    text-align: center;
    user-select: none;
}

.custom_pagination .MuiTablePagination-actions button:nth-child(1) {
    margin-right: 90px;
}

.custom_pagination .MuiTablePagination-actions button {
    border: 1px solid #067de2;
    padding: 3px;
}

.custom_pagination .MuiTablePagination-actions button svg {
    color: #067de2 !important;
}

.custom_pagination .MuiTablePagination-actions button:hover {
    background-color: #067de2;
    transition: 0.3s ease-in-out;
}

.custom_pagination .MuiTablePagination-actions button:hover svg {
    color: #067de2 !important;
    transition: 0.3s ease-in-out;
}

.application {
    padding-top: 4.5rem !important;

}

h4 {
    margin-left: 72px;
    font-size: 17px;
    /* font-family: Poppins-Medium !important; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.MuiAppBar-positionFixed {
    top: 0;
    left: auto;
    right: 0;
    position: absolute !important;
}

.MuiGrid-grid-lg-6 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 50% !important;
}

.modal_header_offer {
    background-color: #067de2;
    color: #fff;
}

.offerModalCloseIcon>span>svg {
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 50px !important;
    padding: 0px !important;
    font-size: 21px !important;
}

.graph-img {
    height: 200px;
}

.graph-heading {
    font-size: 11px;
    margin: 10px 0px 0px 250px;
    font-weight: bold;
    position: absolute;
}

/* Responsive */
@media screen and (min-width: 1200px) {
    .form_layout {
        margin: 10px 0px !important;
        padding: 20px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .form_layout_btn button {
        margin: 30px 10px !important;
        padding: 7px 30px !important;
        border-radius: 25px !important;
        padding: 8px 30px !important;
        font-size: 12px !important;
    }
}

@media screen and (max-width: 360px) {
    .form_layout {
        padding: 15px;
    }
}


/* custom_pagination */
.custom_pagination .MuiTablePagination-toolbar {
    position: relative;
    margin: 10px 0px;
}

.custom_pagination .MuiTablePagination-toolbar .MuiTablePagination-caption {
    position: absolute;
    margin: 0px auto;
    transform: translateX(60px);
    width: 75px;
    text-align: center;
    user-select: none;
}

.custom_pagination .MuiTablePagination-actions button:nth-child(1) {
    margin-right: 90px;
}

.custom_pagination .MuiTablePagination-actions button {
    border: 1px solid #067de2;
    padding: 3px;
    height: 28px !important;
    width: 28px !important;
    border-radius: 50px;
}

.custom_pagination .MuiTablePagination-actions button svg {
    color: #067de2 !important;
    padding-left: 4px;
}

.custom_pagination .MuiTablePagination-actions button:hover {
    background-color: #067de2;
    transition: 0.3s ease-in-out;
}

.custom_pagination .MuiTablePagination-actions button:hover svg {
    color: #fff !important;
    transition: 0.3s ease-in-out;
}

/* Font CSS */
.fontBold {
    font-weight: 600 !important;
}

.fontBoldsubmit {
    font-weight: 200 !important;
}

.primaryColor {
    color: #067de2 !important;
}

.primaryColor svg {
    color: #067de2 !important;
}

/* Modal CSS */
.modal_header {
    background: #067de2;
    color: #fff;
    padding: 10px 24px !important;
}

.modal_header svg {
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 5px;
    font-size: 16px;
}

.modal_header button {
    top: 0 !important;
}

.modal_body {
    padding: 20px 60px !important;
}

.modal_offer {
    padding: 10px 30px !important;
}

.MuiDialogContent-dividers {
    border: 2px solid #067de2 !important
}

/* Responsive */
@media screen and (min-width: 1200px) {
    .form_layout {
        margin: 10px 0px !important;
        padding: 20px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .form_layout_btn button {
        margin: 30px 10px !important;
        padding: 7px 30px !important;
        border-radius: 25px !important;
        padding: 8px 30px !important;
        font-size: 12px !important;
    }
}

@media screen and (max-width: 360px) {
    .form_layout {
        padding: 15px;
    }
}


.table .MuiTableCell-root {
    border: none !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.MuiTableCell-body{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.MuiTableCell-root{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.MuiButton-root {
    min-width: 150px !important;
}


.jss3 {
    margin: none !important
}

.colors .MuiInputBase-input[placeholder] {

    color: black !important;
}

.circularProgess>svg:not(:root) {
    margin-right: 0px !important;
}

input[type="button" i] {
    border: 0px solid transparent !important;
    outline: none !important;
    border-color: transparent !important;
    font-weight: bold;
    background-color: #e9e9e9 !important;
    /* font-family: sans-serif !important; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
}

.MuiNativeSelect-select:not([multiple]) option,
.MuiNativeSelect-select:not([multiple]) optgroup {
    background-color: white;
    color: black !important;
}

.MuiNativeSelect-select:focus {
    color: black !important;
    background-color: none !important;
}

.radiosrow {
    display: inline-flex;
    flex-direction: row !important;
    padding-left: 3px;
}

.radiosrows {
    display: inline-flex;
    flex-direction: row !important;
}

.form_layout_btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form_layout_btns button span.MuiButton-label {
    font-size: 14px !important;
}

@media screen and (min-width: 1200px) {
    .form_layout {
        margin: 10px 0px !important;
        padding: 20px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .form_layout_btns button {
        margin: 18px 10px !important;
        border-radius: 25px !important;
        padding: 8px 30px !important;
        font-size: 12px !important;
    }
}

@media screen and (max-width: 360px) {
    .form_layout {
        padding: 15px;
    }
}

.MuiSelect-selectMenu {

    min-height: 1.1876em !important
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .radioLabel {
        min-width: 140px;
        max-width: 140px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
    .form_layout_div label {
        min-width: 150px !important;
        max-width: 150px;
        padding-right: 20px;
    }

    .form_layout_div1 label {
        min-width: 150px !important;
        max-width: 150px;
        padding-right: 20px;
    }

    .form_layout_div2 label {
        min-width: 150px !important;
        max-width: 150px;
        padding-right: 20px;
    }

    .form_layout_div input {
        min-width: 180px !important;
    }

    .form_layout_div1 input {
        min-width: 180px !important;
    }

    .form_layout_div2 input {
        min-width: 180px !important;
    }

    .form_layout_div .form_select {
        min-width: 186px;
        max-width: 186px;
    }

    .radiosrow {
        padding-left: 20px !important;
    }

    .radioLabel {
        min-width: 140px;
        max-width: 140px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .form_layout_div label {
        min-width: 150px !important;
        max-width: 150px;
        padding-right: 20px;
    }

    .form_layout_div1 label {
        min-width: 150px !important;
        max-width: 150px;
        padding-right: 20px;
    }

    .form_layout_div2 label {
        min-width: 150px !important;
        max-width: 150px;
        padding-right: 20px;
    }

    .form_layout_div input {
        min-width: 220px !important;
    }

    .form_layout_div1 input {
        min-width: 220px !important;
    }

    .form_layout_div2 input {
        min-width: 220px !important;
    }

    .form_layout_div .form_select {
        min-width: 225px;
        max-width: 225px;
    }

    .radiosrow {
        padding-left: 20px !important;
    }

    .radioLabel {
        min-width: 150px;
        max-width: 150px;
    }
}

@media screen and (min-width: 1200px) {
    .radioLabel {
        padding-right: 40px;
        font-weight: 600;
        min-width: 220px;
        max-width: 220px;
        font-size: 14px;
    }
}

/* Custom CSS */
.pt-0 {
    padding-top: 0 !important;
}

.radiosrow {
    float: left;
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        .radioLabel {
            font-weight: normal !important;
        }

        .form_layout_div input {
            background: #fff;
        }

        select {
            background: #fff !important;
        }
    }
}

.form_layout_div input[type="date"] {
    min-height: 27px;
}

/* Reusable css */
.d-flex {
    display: flex;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.py {
    padding: 10px 0px;
}

.form-control {
    min-width: 180px !important;
}


/****Review Matrix ***/

#TblHeadTitle {
    padding: 0px;
    font-weight: bold;
    font-size: 14px;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    width: 310px;
    padding-left: 6px !important;
    background-color: #e9e9e9;
}

#TableHeadCell {
    border: 1px solid rgb(224, 219, 219);
    font-weight: bold !important;
    font-size: 14px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    padding: 5px !important;
    width: 80px;
    background-color: #e9e9e9
}

.MuiInputBase-input.Mui-disabled {
    opacity: 1;
    background: #f4f0ec;
}

.circularProgress {
    margin: 250px !important
}

/*** History Css **/

#History {
    padding: 0px 30px 20px 30px
}

.history {
    overflow-y: auto !important;
}

#TableCellAppStatus {
    cursor: auto !important;
}

.history {
    overflow-y: auto !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0px !important;
}

.googleMapApi_autocomplete>div {
    margin-top: 0px !important;
    outline: transparent !important;
}

#google_suggestion_address {
    border: none !important;
    height: 20px !important
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.80) !important;
}

/*** matrix loader **/

.loaderProgress {
    margin: 100px !important;
    width: 500px !important
}

/**Change history + symbol to center **/

.showPsw .MuiInputAdornment-positionEnd {
    margin-left: 50px !important;
}


/** Start of send offer template styles **/

.offer-template-name {
    margin-left: 15%;
}

/** End of send offer template styles **/


.MuiTypography-body1{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.pagination-span{
    /* padding: 15px; */
    margin: 15px;
    cursor: pointer;
}

.prev{
    margin-top: 1px;
    margin-right: 0px !important;
    border: 1px solid #067de2;
    border-radius: 20px;
    width: 10px !important;
    height: 11px !important;
    padding: 6px;
    padding-left: 8px;
}
.next{
    margin-top: 1px;
    border: 1px solid #067de2;
    border-radius: 20px;
    width: 11px !important;
    height: 11px !important;
    padding: 6px;
}
.next-transition{
    transition: color 0.5s ease !important;
    cursor: pointer;
}
.next-transition:hover{
    color: #0600ff  !important;
}
.comparable-select{
    width: 100% !important;
}