.align-end{
    display: grid;
    justify-content: end;
}
.header-container{
    border-bottom: 2px solid black;
    padding-bottom: 11px;
}
.header-text{
    /* font-family: Poppins-Regular !important; */
    font-weight: bold !important;
    font-size: 15px !important;
}
.val-details{
    /* font-family: Poppins-Regular !important; */
    font-weight: bold !important;
    font-size: 15px !important;
}
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 104%; 
    left: 23%;
    margin-left: -60px; 
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
