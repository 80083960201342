svg:not(:root) {
    overflow: hidden;
    /* color: #067de2 !important; */
}

.modal_header svg{
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 50px !important;
    padding: 0px !important;
    font-size: 21px !important;
}