.apps {
    width: 100%;
}

.TblRadioCell {
    padding: 0px !important
}

.TableContainer {
    height: 440px;
    width: 100%;
    border: 1px solid grey;
    font-size: 14px;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.MuiPaper-root .MuiDialog-paper .MuiDialog-paperScrollPaper .MuiDialog-paperWidthSm .MuiPaper-elevation24 .MuiPaper-rounded {
    max-width: 700px !important
}

.btn {
    padding: 10px;
}


.TblCell {
    padding: 6px !important;
    /* border: 0.1px groove rgb(245, 240, 240); */
    border-right: 0.1px groove rgb(245, 240, 240);
    font-size: 14px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: black !important;
    font-weight: 540px;
    line-height: 1;
}

.modal_body1 {
    padding: 0px !important;
    overflow-y: hidden !important;
}

.TableHeader {
    width: 100%;
}

.TableBody {
    width: 100%;
}

.TableRow {
    width: 100%;
    background-color: white !important;
}

.table {
    width: 100%;
}

.TblTermCell {
    font-weight: bold !important;
    padding: 6px !important;
    background-color: white;
    font-size: 14px !important;
    color: black !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.MatrixtblRow {
    background-color: white !important;
}

.TblHead {
    margin: 0px !important;
    background-color: #e9e9e9;
    height: 10px;
}

.TblHeadRow {
    font-size: 5px;
    background-color: #e9e9e9;
}

.TableHeadCell {
    border: 1px solid rgb(224, 219, 219);
    font-weight: bold !important;
    font-size: 14px !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    padding: 5px !important;
    width: 80px;
}

#TblHeadTitle {
    padding: 0px;
    font-weight: bold;
    font-size: 14px;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    width: 310px;
    padding-left: 6px !important;
}

.TblHeadSubTitle {
    font-weight: bold !important;
    font-size: 14px;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

#TblRadioRow {
    padding: 0px !important;
    background-color: #e9e9e9
}


.TblRadioCell svg.MuiSvgIcon-root {
    color: #067de2 !important;
}

.TblRadioCell svg.MuiSvgIcon-root.PrivateRadioButtonIcon-layer-34 {
    color: #067de2 !important;
}

#TblFooter {
    padding: 10px;
}

#TblRadioDiv {
    padding-left: 25px;
    height: 25px;
}

.RadioButton {
    padding-top: 2px;
}

.radioClass {
    color: #067de2 !important;
}

.icon {
    color: white;
    height: 1em;
    font-size: 2em;
}

.modal_head svg {
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 50px !important;
    padding: 0px !important;
    font-size: 15px !important;
    margin-right: 0px !important;
}

#table_btn1 {
    font-size: 0px !important;
}

.primary_btn11 {
    margin: 2px 10px !important;
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    background: #067de2 !important;
    height: 35px;
}

.btn_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
}


.loan_officer_edit_heading {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 15px;
    color: red;
    font-size: 16px;
    font-weight: bold;
}

.loan_officer_edit_heading_link {
    text-decoration: underline;
    font-size: 16px !important;
    cursor: pointer;
}

.primary_btn12 {
    margin: 2px 10px !important;
    border-radius: 25px !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    height: 35px;
}



.btn {
    font-size: 12px !important;
}

.span_btns {
    font-size: 12px !important;
}

.closeIcons {
    color: white
}


.form_layout_btn_matrix {
    justify-content: flex-end;
    padding-top: 20px;
}

.Matrix_modal_grid {
    display: flow-root;
}

.form_layout_btn button span.MuiButton-label {
    font-size: 14px !important;
}

.primary_btn11 span.MuiButton-label {
    font-size: 14px !important;
}

.primary_btn12 span.MuiButton-label {
    font-size: 14px !important;
}


.TableContainer_layout {
    padding: 10px;
    width: 700px !important;
    width: 100% !important;
    height: 94% !important
}

.borderremovel {
    border: 2px solid transparent !important;
}

.showBorrower {
    display: block;
}

.hideBorrower {
    display: none;
}


.dropdowncolor {
    height: 44px !important;
    background-color: white !important;
    min-height: 1.6em !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.circular_spiner svg {
    margin-right: 0 !important;
}